<template>
	<div class="app-rich-text">
		<vue-ueditor-wrap @ready="ready" @beforeInit="__init" :config="editorConfig" :editor-id="id" v-model="tempContent"></vue-ueditor-wrap>
		<app-attachment style="height: 0" :simple="simpleAttachment" :open-dialog="attachmentDialogVisible" :multiple="!simpleAttachment"
		 @closed="attachmentClosed" @selected="attachmentSelected">
		</app-attachment>
	</div>
</template>

<script>
	import appAttachment from '@/components/app-attachment.vue';
	import VueUeditorWrap from 'vue-ueditor-wrap';
	export default {
		props: {
			value: null,
			simpleAttachment: false,
		},
		components: {
			appAttachment,
			VueUeditorWrap
		},
		data() {
			return {
				attachmentDialogVisible: false,
				id: 'app-rich-text-' + (Math.floor((Math.random() * 10000) + 1)),
				ue: null,
				editorConfig: {
					// 访问 UEditor 静态资源的根路径，可参考 https://hc199421.gitee.io/vue-ueditor-wrap/#/faq
					UEDITOR_HOME_URL: process.env.NODE_ENV === 'production' ? '/store/UEditor/' : '/UEditor/',
					// 初始容器高度
					initialFrameHeight: 450,
					// 初始容器宽度
					initialFrameWidth: 400,
				},
				isInputChange: false,
				tempContent: '',
			};
		},
		created() {
			this.tempContent = this.value
		},
		watch: {
			value(newVal, oldVal) {
				if (!this.isInputChange && newVal) {
					if (this.ue && this.ue.isReady === 1) {
						this.ue.setContent(newVal);
					} else {
						this.tempContent = newVal;
					}
				}
				if (this.isInputChange) {
					this.isInputChange = false;
				}
			},
		},
		methods: {
			__init() {
				this.loadUe()
			},
			ready(editorInstance) {
				this.ue = editorInstance;
				this.ue.addListener('keyup', editor => {
					this.isInputChange = true;
					this.$emit('input', this.ue.getContent());
				});
				this.ue.addListener('contentChange', editor => {
					this.isInputChange = true;
					this.$emit('input', this.ue.getContent());
				});
			},
			attachmentClosed() {
				this.attachmentDialogVisible = false;
			},
			attachmentSelected(e) {
				if (e.length) {
					let html = '';
					for (let i = 0; i < e.length; i++) {
						html += '<img src="' + e[i].file_path +
							'?imageMogr2/auto-orient/thumbnail/600x/blur/1x0/quality/75" style="max-width: 100%;">';
					}
					this.ue.execCommand('inserthtml', html);
				}
			},
			loadUe() {
				const vm = this;
				window.UE.registerUI('appinsertimage', (editor, uiName) => {
					return new window.UE.ui.Button({
						name: uiName,
						title: '插入图片',
						//添加额外样式，指定icon图标，这里默认使用一个重复的icon
						cssRules: 'background-position: -381px 0px;',
						onclick() {
							vm.ue = editor
							vm.attachmentDialogVisible = true;
						},
					});
				});
			},
		}
	}
</script>

<style>
	.app-rich-text {
		line-height: normal;
	}
</style>
